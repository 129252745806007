<template>
    <header class="header site-header ready header-sticky-native">
        <div class="site-header-inner">
            <h1 class="logo-wrapper">
                <router-link :to="{path:'/'}">
                    <span class="logo"><span>BING XU</span></span>
                </router-link>
            </h1>
            <div class="site-header-row">
                <!--左边的菜单导航-->
                <div class="mainMenu main-menu ready">
                    <button class="main-menu-toggler" v-show="lockOpen == false" @click="showNav">
                <span class="icon">
                  <span class="icon-hamburger">
                    <i></i>
                  </span>
                </span>
                    </button>
                    <button class="main-menu-toggler main-menu-toggler-open" v-show="lockOpen == true"
                            @click="closeNav">
             <span class="icon">
               <span class="icon-hamburger">
                 <i></i>
               </span>
             </span>
                    </button>
                    <!--   一级导航-->
                    <van-popup
                        v-model="pops"
                        position="left"
                        :overlay-style="{top:'3.125rem',height:'100%',maxHeight:'calc(100% - 3.125rem)',background:'none'}"
                        :style="{width:'100%',height:'100%',marginTop:'3.125rem',background:'none'}"
                        style="z-index:200">
                        <div style="width: 100%;minHeight:calc(100% - 3.125rem);background: rgba(255, 255, 255, 0.95)">
                            <ul class="main-menu-list">
                                <li v-for="(item, index) in categories" :key="item.id" @click="showDetail(index)">
                                    <button class="main-menu-entry">
                                        <span class="icon"></span>
                                        <span class="text">{{ item.name.en }}</span>
                                    </button>
                                </li>
                                <li v-show="Object.keys(userInfo).length" @click="toMyAccount">
                                    <router-link :to="{path:'/myAccount'}" class="main-menu-entry changeColor">
                                        <span class="icon"></span>
                                        <span class="text">My Account</span>
                                    </router-link>
                                </li>
                                <li v-show="!Object.keys(userInfo).length" @click="showLogin">
                                    <div>
                                        <button class="main-menu-entry changeColor">
                                            <span class="icon"></span>
                                            <span class="text">Login</span>
                                        </button>
                                    </div>
                                </li>
                                <!--                <li>-->
                                <!--                  <a href="#" class="main-menu-entry changeColor">-->
                                <!--                    <span class="icon"></span>-->
                                <!--                    <span class="text">Call us</span>-->
                                <!--                  </a>-->
                                <!--                </li>-->
                                <!--                <li>-->
                                <!--                  <a href="#" class="main-menu-entry changeColor">-->
                                <!--                    <span class="icon"></span>-->
                                <!--                    <span class="text">Email us</span>-->
                                <!--                  </a>-->
                                <!--                </li>-->
                            </ul>
                        </div>
                    </van-popup>
                </div>
                <!--  shop women的二级导航-->
                <div class="shopPop">
                    <van-popup
                        v-model="showDetails"
                        position="right"
                        :overlay-style="{top:'3.125rem',height:'100%',maxHeight:'calc(100% - 3.125rem)',background:'none'}"
                        :style="{width:'100%',height:'100%',marginTop:'3.125rem',background:'#fff'}">
                        <ul class="main-menu-list mainDetail">
                            <li @click="backNav" class="backicon">
                                <button class="main-menu-entry">
                                    <span class="text">Back</span>
                                    <span class="icon"></span>
                                </button>
                            </li>
                            <div
                                v-if="categories[currentSelectedCategoryIndex] && categories[currentSelectedCategoryIndex].subCategories !== undefined">
                                <li v-for="(item,index) in categories[currentSelectedCategoryIndex].subCategories"
                                    :key="index"
                                    @click="appearDia(item.id)">
                  <span class="main-menu-entry">
                    <span class="icon"></span>
                    <span class="text">{{ item.name.en }}</span>
                  </span>
                                </li>
                            </div>
                        </ul>
                    </van-popup>
                </div>
                <!--右边-->
                <ul class="menu-helper">
                    <li>
                        <div class="searchIon">
                            <span class="icon" @click="searchFn"></span>
                            <div class="seachBox" v-show="show">
                                <div style="transform:translateY(-100%)">
                                    <div class="showSearch" :class="{'actives':show == true}">
                                        <form>
                                            <input placeholder="Type to search" v-model="toSearch" @blur="iptBlur()"/>
                                            <button><span></span></button>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </li>
                    <li class="shopping-bag" @click="showDia">
                        <div class="inner">
                            <span class="icon"></span>
                            <span class="count">0</span>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
        <!-- 登陆的弹框-->
        <div class="loginDialog">
            <van-popup
                v-model="loginPop"
                position="right"
                closeable
                close-icon-position="top-right"
                :overlay-style="{top:'3.125rem',height:'100%',maxHeight:'calc(100% - 3.125rem)',background:'none'}"
                :style="{width:'100%',height:'100%',marginTop:'3.125rem',background:'#fff'}">
                <div class="loginBox" v-if="isRegister == 1">
                    <h2>Login</h2>
                    <div class="inner mfp-content-scrollable-area">
                        <div class="mfp-content-flex-item">
                            <div class="mfp-content-holder">
                                <div class="login-page user-section account-section">
                                    <section class="login-container">
                                        <van-form @submit="onSubmit">
                                            <van-field
                                                v-model="formLabelAlign.email"
                                                name="Email address"
                                                label="Email address *"
                                                label-align="top"
                                                input-align="left"
                                                label-width="100%"
                                                placeholder="Your email address"
                                                required
                                                :rules="[{ emailPattern, message: '请输入正确内容' }]">
                                            </van-field>
                                            <van-field
                                                v-model="formLabelAlign.password"
                                                type="password"
                                                name="Password"
                                                label="Password *"
                                                label-align="top"
                                                input-align="left"
                                                label-width="100%"
                                                placeholder="Password"
                                                required
                                            >
                                            </van-field>
                                            <van-button plain @click="confirmLogin">Login</van-button>
                                            <div class="serverSideErrors">
                                                <div class="mandatory">* Required fields</div>
                                                <div @click="forgetPwd">Forgot your password?</div>
                                                <div class="consult">Consult our<router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link>for
                                                    further information.
                                                </div>
                                            </div>
                                        </van-form>
                                    </section>

                                    <section class="registration-container">
                                        <div class="registrBox">
                                            <p>Register now and make the most of My Account. You will be able to:</p>
                                            <ul class="list-advantages">
                                                <li>Receive our exclusive newsletter</li>
                                                <li>Save your favorite items</li>
                                                <li>Shop faster</li>
                                                <li>Check your orders and returns</li>
                                            </ul>
                                            <van-button plain @click="showRegisterFn">Register</van-button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="registerBox " v-if="isRegister == 2">
                    <div class="mfp-content">
                        <h2 class="title">Registration</h2>
                        <div class="inner mfp-content-scrollable-area">
                            <div class="mfp-content-flex-item">
                                <div class="mfp-content-holder">
                                    <section class="accountUserForm registration-page account-section">
                                        <h2>Registration</h2>
                                        <div class="registration-wrapper">
                                            <div class="registration-advantages-wrapper">
                                                <p class="registration-advantages">Register now and make the most of My
                                                    Account. You will be
                                                    able to:</p>
                                                <ul>
                                                    <li>Receive our exclusive newsletter</li>
                                                    <li>Save your favorite items</li>
                                                    <li>Shop faster</li>
                                                    <li>Check your orders and returns</li>
                                                </ul>
                                            </div>
                                            <div class="form-wrapper">
                                                <el-form ref="registerForm" :rules="registerRules" :label-position="labelPosition"
                                                         :model="registerForm"
                                                         class="demo-ruleForm" size="small"
                                                         :hide-required-asterisk="true">
                                                    <div class="requires">* Required fields</div>
                                                    <el-form-item label="First Name *" prop="firstName">
                                                        <el-input v-model="registerForm.firstName"
                                                                  placeholder="First Name"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Last Name *" prop="lastName">
                                                        <el-input v-model="registerForm.lastName"
                                                                  placeholder="Last Name"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Email address *" prop="email">
                                                        <el-input v-model="registerForm.email"
                                                                  placeholder="Your email address"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Password *(6 - 15 characters)" prop="password">
                                                        <el-input v-model="registerForm.password" placeholder="Password"
                                                                  type="password"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Confirm Password *" prop="confirmPassword">
                                                        <el-input v-model="registerForm.confirmPassword"
                                                                  placeholder="Confirm Password"
                                                                  type="password"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Date of birth" prop="confPass"
                                                                  class="dateBirth">
                                                        <div class="block">
                                                            <el-date-picker
                                                                v-model="dateValue"
                                                                type="date"
                                                                placeholder="Date of birth"
                                                                class="dateEletion">
                                                            </el-date-picker>
                                                        </div>
                                                    </el-form-item>
                                                    <div class="fieldRow">This site is protected by reCAPTCHA and the
                                                        Google <router-link target="_blank" :to="{path: '/privacy'}" >Privacy
                                                            Policy</router-link>and<a href="#">Terms of Service</a>apply.
                                                    </div>
                                                    <div class="privacyContainer">
                                                        <div class="privacy">In order to enhance your experience on
                                                            bingxu.com, personal data will
                                                            be electronically processed by BING XU. Your data will be
                                                            strictly controlled by BING XU
                                                            for customer management, marketing and other purposes as
                                                            detailed in our <router-link target="_blank" :to="{path: '/privacy'}" >Privacy
                                                                Policy</router-link></div>
                                                        <div class="acceptFlags">
                                                            <van-checkbox-group v-model="checked">
                                                                <van-checkbox name="a" shape="square" icon-size=".5rem"
                                                                              checked-color="#212121">I agree
                                                                    that BING XU may collect my personal information for
                                                                    marketing purposes (newsletters,
                                                                    updates and collection arrivals etc.).
                                                                </van-checkbox>
                                                                <van-checkbox name="b" shape="square" icon-size=".5rem"
                                                                              checked-color="#212121">I
                                                                    consent to the processing of my personal data by
                                                                    BING XU for customer satisfaction
                                                                    purposes and for customizing my user experience to
                                                                    my interests or my shopping habits.
                                                                </van-checkbox>
                                                            </van-checkbox-group>
                                                        </div>
                                                        <div class="buttonsRow">
                                                            <button type="button" @click="confirmRegister('registerForm')">Register
                                                            </button>
                                                        </div>

                                                    </div>

                                                </el-form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </van-popup>
        </div>
        <!-- 忘记密码-->
        <div class="forgetPwdbox">
            <van-popup
                v-model="forgetpwd"
                position="bottom"
                closeable
                close-icon-position="top-right"
                :overlay-style="{top:'3.125rem',height:'100%',maxHeight:'calc(100% - 3.125rem)',background:'none'}"
                :style="{width:'100%',height:'100%',top:'3.125rem',background:'#fff'}">
                <div class="mfp-content" v-if="isLogin==1">
                    <van-form @submit="onSubmit">
                        <p>Enter the email address you use for My Account and you will receive an email containing a
                            link to change
                            your password.</p>
                        <div class="require">* Required fields</div>
                        <div class="forVan">
                            <van-field
                                v-model="email"
                                name="Email address"
                                label="Email address *"
                                label-align="top"
                                input-align="left"
                                label-width="100%"
                                placeholder="Your email address"
                                required
                                :rules="[{ emailPattern, message: 'The email address field is required' }]">
                            </van-field>
                        </div>

                        <div class="buttonsRow">
                            <van-button plain class="submit">Send</van-button>
                            <van-button plain class="back" @click="backFn">Back to login</van-button>
                        </div>

                    </van-form>
                </div>
                <!--  返回登录-->
                <div class="mfp-content-holder back-holder" v-if="isLogin==2">
                    <div class="backForm ">
                        <van-form @submit="onSubmit">
                            <p>Log in with your email and password.</p>
                            <div class="requireds">* Required fields</div>
                            <div class="backFie">
                                <van-field
                                    v-model="email"
                                    name="Email address"
                                    label="Email address *"
                                    label-align="top"
                                    input-align="left"
                                    label-width="100%"
                                    placeholder="Your email address"
                                    required
                                    :rules="[{ emailPattern, message: 'The email address field is required' }]">
                                </van-field>
                                <van-field
                                    v-model="email"
                                    name="Password"
                                    label="Password *"
                                    label-align="top"
                                    input-align="left"
                                    label-width="100%"
                                    placeholder="Password"
                                    required
                                    :rules="[{pattern, message: 'The Password field is required' }]">
                                </van-field>
                            </div>
                            <p class="forgets">Forgot your password?</p>
                            <div class="const">Consult our<router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link>for further information.</div>
                            <div class="rowBtn">
                                <van-button plain class="submit">login</van-button>
                            </div>

                        </van-form>
                    </div>
                </div>
            </van-popup>
        </div>

        <!-- 弹框-->
        <div class="showBagDialog" style="width: 100%;height: 100%;" v-if="showContent">
            <el-drawer
                :visible.sync="showContent"
                :modal="false"
                closeable
                close-icon-position="top-right"
                :overlay-style="{top:'3.125rem',height:'100%',maxHeight:'calc(100% - 3.125rem)',background:'none'}"
                :style="{width:'100%',height:'100%',top:'3.125rem',background:'#fff'}">
                <div class="shopping-bag-title">
                    <a href="#">
                        <span>Shopping Bag</span>
                        <span class="counts">{{
                                Object.keys(userInfo).length ? userInfo.cartProductQuantity : "0"
                            }}</span>
                    </a>
                    <div class="closeDia" @click="closeDiaFn">
                        <span></span>
                    </div>
                </div>
                <div class="shopping-bag-content ">
                    <!-- <p>No items</p>-->
                    <div class="shopping-bag-list">
                        <div
                            class="shopping-bag-list-product"
                            v-if="Object.keys(userInfo).length && userInfo.carts.list"
                        >
                            <div
                                :key="index"
                                v-for="(item, index) in userInfo.carts.list"
                                class="shopping-bag-element"
                            >
                                <div class="shopping-bag-element-image" style="background-color: #f8f7f5;">
                                    <img
                                        :src="item.productThumbnail" style="height:auto"
                                    >
                                </div>
                                <div class="shopping-bag-element-info">
                                    <div class="element-info element-title">
                                        <span>{{ item.productName }}</span>
                                    </div>
                                    <div class="element-info element-price">
                                        <div class="itemPrice">
                                            <span>HK$ {{ fenToYuan(item.amount) }}</span>
                                        </div>
                                    </div>
                                    <div v-if="item.pack">
                                        <span>Pack: {{ item.pack.name }}</span>
                                    </div>
                                    <div class="element-info element-size-color">
                                        <div
                                            :key="k"
                                            v-for="(sku, k) in item.productSku"
                                            class="element-size"
                                        >{{ k }}: {{ sku }}
                                        </div>
                                        <div>Quantity: {{ item.quantity }}</div>
                                    </div>
                                    <div class="removeBtn">
                                        <button @click="removeCartItem(item.id)">Remove</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shopping-bag-footer">
                    <div class="prices products-price">
                        <span class="label">Subtotal:</span>
                        <span class="valueToPay">HK$ {{
                                Object.keys(userInfo).length ? fenToYuan(userInfo.carts.subTotal) : "0.00"
                            }}</span>
                    </div>
                    <router-link
                        v-if="Object.keys(userInfo).length && userInfo.carts.list"
                        :to="{ path: '/checkout' }"
                        class="goToCheckoutLink"
                    >
                        <button @click="closeDia" style="width: 100%;">Proceed to checkout</button>
                    </router-link>
                </div>
            </el-drawer>
        </div>
    </header>
    <!--  当屏幕小于768px-->
</template>
<script>
import {f, priceTransform} from "@/utils/util";
import {login, register, removeCartItem} from "@/api/api";
import {EventBus} from "../utils/event-bus.js";

export default {
    // name: "MobileHeader",
    data() {
        return {
            dateValue: "",
            showContent: false,
            toSearch: "",
            currentSelectedCategoryIndex: null,
            categoId: null,
            show: false,
            bags: false,
            lockOpen: false,
            pops: false,//一级导航
            showDetails: false, //二级导航
            loginPop: false,//登录
            isRegister: 1,//默认是登陆弹框
            emailPattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
            pattern: /\d{6}/,
            email: "",
            forgetpwd: false,//忘记密码
            isLogin: 1,
            checked: [],
            labelPosition: "top",
            registerRules: {
                firstName: [
                    {required: true, message: "The first name field is required", trigger: "blur"}
                ],
                lastName: [
                    {required: true, message: "The last name field is required", trigger: "blur"}
                ],
                email: [
                    {type: "email", required: true, message: "The email address field is required", trigger: "blur"}
                ],
                password: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ],
                confirmPassword: [
                    {required: true, message: "The confirm password field is required", trigger: "blur"}

                ]
            },
            formLabelAlign: {
                email: "",
                password: ""
            },
            registerForm: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                birthYear: "",
                birthMonth: "",
                birthDay: ""
            },
            value: ""
        };
    },
    mounted() {
        console.log("mobile", f(this.categories));
        //监听打开购物车事件
        EventBus.$off("openH5ShoppingBagEvent");
        EventBus.$on("openH5ShoppingBagEvent", () => {
            this.showDia();
        });
    },
    props: {
        categories: Array,
        userInfo: Object
    },
    computed: {},
    methods: {
        fenToYuan(fen) {
            return priceTransform(fen);
        },
        async removeCartItem(id) {
            const response = await removeCartItem(id);
            console.log(response);
            if (response.code === 0) {
                this.$message({
                    showClose: true,
                    message: "Success",
                    type: "success"
                });
                this.$parent.fetchUserInfo();

            } else {
                this.$message({
                    showClose: true,
                    message: response.message,
                    type: "error"
                });
            }
        },
        searchFn() {
            this.showContent = false;
            this.show = !this.show;
        },
        iptBlur() {
            window.location.href = "/shop?keyword=" + this.toSearch;
        },
        showDia() {
            this.showContent = true;

            // this.bags = true;
        },
        closeDia() {
            this.showContent = false;

            // this.bags = true;
        },
        toMyAccount() {
            this.pops = false;
        },
        closeDiaFn() {
            this.bags = false;
        },
        showDetail(index) {
            this.currentSelectedCategoryIndex = index;
            if (this.categories[index].subCategories != undefined) {
                this.showDetails = true;
                this.pops = true;
            } else {
                // this.$router.push({path:'/shop',pareId:index.id})
                window.location.href = "/shop?parentCategoryId=" + this.categories[index].id + "&subCategoryId=" + "";
                this.showDetails = false;
                this.pops = false;
            }

        },
        appearDia(subId) {
            window.location.href = "/shop?parentCategoryId=" + this.categories[this.currentSelectedCategoryIndex].id + "&subCategoryId=" + subId;
            this.showDetails = false;
            this.pops = false;
            this.lockOpen = false;
        },
        backNav() {
            this.showDetails = false;
            this.pops = true;
        },
        showNav() {
            this.pops = true;
            this.lockOpen = true;
            this.showContent = false;
        },
        closeNav() {
            this.pops = false;
            this.showDetails = false;
            this.lockOpen = false;
        },
        // 登录
        showLogin() {
            this.pops = false;
            this.lockOpen = false;
            this.loginPop = true;
            this.isRegister = 1;
        },
        // 展示注册
        showRegisterFn() {
            this.isRegister = 2;
        },
        onSubmit() {

        },
        //忘记密码
        forgetPwd() {
            this.forgetpwd = true;
            this.loginPop = false;
            this.isLogin = 1;
        },
        backFn() {
            this.isLogin = 2;
        },
        async confirmRegister(formName) {
            if (this.checked.length < 2) {
                this.$message({
                    showClose: true,
                    message: 'Please check to agree to the relevant agreement',
                    type: "error"
                });
                return;
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.dateValue) {
                        this.registerForm.birthYear = this.$moment(this.dateValue).format("YYYY");
                        this.registerForm.birthMonth = this.$moment(this.dateValue).format("MM");
                        this.registerForm.birthDay = this.$moment(this.dateValue).format("DD");
                    }
                    const {
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                        birthDay,
                        birthMonth,
                        birthYear
                    } = this.registerForm;
                    const res = await register({
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                        birthDay,
                        birthMonth,
                        birthYear
                    });
                    console.log(res);
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: "Registered successful",
                            type: "success"
                        });
                        this.loginPop = false;
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        async confirmLogin() {
            const {email, password} = this.formLabelAlign;
            const res = await login({email, password});
            const {code, data, message} = res;
            if (code !== 0) {
                this.$message({
                    showClose: true,
                    message: message,
                    type: "error"
                });
            } else {
                this.loginPop = false;
                const {accessToken} = data;
                this.accessToken = accessToken;
                sessionStorage.setItem("accessToken", accessToken);

                this.$parent.fetchUserInfo();

                this.$message({
                    showClose: true,
                    message: "Login successful",
                    type: "success"
                });
                if (this.$route.path === "/login") {
                    this.$router.push({path: "/overView"});
                }
            }
            console.log(res);
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep .dateEletion .el-input__prefix {
    left: -5px;
}

::v-deep .dateEletion .el-input__inner {
    margin-left: 1.5rem;
    width: 10rem;
}
button {
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;

    &:focus {
        outline: 0;
    }
}

header, .site-header-inner, .site-header-row {
    position: relative;
    height: 3.125rem;
    top: 0;
    left: 0;
    width: 100%;
}

h2 {
    font-size: 1em;
    margin: 0;
    font-weight: 400;
}

header {
    //position: sticky;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
}

.site-header-row, .menu-helper, .inner, .shopping-bag-title {
    display: flex;
    align-items: center;
}

.logo-wrapper {
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    .logo {
        background-image: url(../assets/logo.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        display: block;
        height: 12px;
        width: 107px;

        span {
            clip: rect(1px, 1px, 1px, 1px);
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            border: 0;
            padding: 0;
            overflow: hidden;
        }
    }
}

.site-header-inner {
    z-index: 1;
}

.site-header-row {
    justify-content: space-between;
    padding: 0 .5625rem 0 .1875rem;
}

.site-header-inner, .site-header-row {
    position: relative;
    height: 3.125rem;
    top: 0;
    left: 0;
    width: 100%;
    //左边
    .main-menu-list {
        max-height: 100%;
        flex: 0 0 auto;
        overflow-y: auto;
        overflow-x: hidden;

        li {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #d8d7d5;

            div {
                display: flex;
                flex-direction: column;
            }

            .main-menu-entry {
                padding-top: .5rem;
                padding-bottom: .5rem;
                min-height: 3.125rem;
                border-bottom: 0 none;
                min-height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                //background: hsla(0,0%,100%,.95);
                padding: 0 2.4375rem 0 1.1875rem;
                text-align: left;

                .icon {
                    width: .5rem;
                    position: absolute;
                    top: 50%;
                    right: 1.3125rem;
                    transform: translateY(-50%);
                }

                .icon:before {
                    background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-right.svg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    content: "";
                    padding: 0 0 100%;
                    display: block;
                }

                .text {
                    text-decoration: none;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    padding-bottom: .0625rem;
                    border-bottom-color: transparent;
                    margin-top: .1675rem;
                    //line-height: 1.1875rem;
                    line-height: 3.17rem;
                    display: block;
                    padding-bottom: 0;
                    max-width: 100%;
                }
            }

            a {
                color: #212121;
            }

            .changeColor {
                background: hsla(60, 3%, 94%, .95);

            }

            .main-menu-entry:first-child {
                min-height: 3.1875rem;
            }
        }

        li:first-child {
            border-top: 1px solid #d8d7d5;
        }
    }

    .mainDetail {
        .clickStyle {
            background: hsla(40, 5%, 88%, .95);
        }

        li.backicon {
            border-bottom: none;

            .icon {
                left: 1.3125rem;
                right: auto;
                transform: translateY(-50%) rotate(180deg);
            }

            .text {
                padding-left: .9375rem;
            }
        }

    }

    .mainMenu {
        z-index: 103;
        position: relative;

        .main-menu-toggler {
            width: auto;
            padding: 1rem;

            .icon {
                display: block;
                position: relative;
                width: .8125rem;
            }

            .icon:before {
                content: "";
                display: block;
                padding: 0 0 100%;
            }

            .icon-hamburger {
                position: absolute;
                top: 50%;
                left: 0;
            }

            .icon-hamburger:before {
                transform: translateY(-.3125rem);
            }

            .icon-hamburger, .icon-hamburger:before, .icon-hamburger:after, .icon-hamburger i, .icon-hamburger i:before, .icon-hamburger i:after {
                display: block;
                height: 1px;
                width: 100%;
            }

            .icon-hamburger:before, .icon-hamburger i:before, .icon-hamburger:after, icon-hamburger i:after {
                background-color: #000;
                content: "";
                position: absolute;
            }

            .icon-hamburger i {
                position: relative;
            }

            .icon-hamburger:after {
                transform: translateY(.3125rem);
            }

            .icon-hamburger i:before, .icon-hamburger i:after, .icon-hamburger:after {
                transition: background-color 0s .4s, transform .4s 0s;
            }
        }

        .main-menu-toggler-open {
            .icon-hamburger:before {
                transition: background-color 0s .4s, transform .4s 0s;
                transform: translateY(0);
                background-color: transparent;
            }

            .icon-hamburger i:before {
                transform: rotate(45deg);
            }

            .icon-hamburger:after {
                transition: background-color 0s .4s, transform .4s 0s;
                transform: translateY(0);
                background-color: transparent;
            }

            .icon-hamburger i:after {
                transform: rotate(-45deg);
            }

            .icon-hamburger i:before, .icon-hamburger i:after {
                transition: background-color 0s .4s, transform .4s .4s;
                background-color: #000;
            }

            .icon-hamburger i:after {
                content: "";
                position: absolute;
            }
        }

    }

    //导航栏右边
    .menu-helper {
        li {
            display: flex;
            align-items: center;
        }

        .searchIon {
            display: flex;
            align-items: center;

            .seachBox {
                z-index: 200;
                position: fixed;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 2;
                padding-bottom: .5rem;
                overflow: hidden auto;
                top: 3.125rem;
            }

            .icon {
                background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/search.svg);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: 50%;
                display: block;
                width: 15px;
            }

            .icon:before {
                content: "";
                display: block;
                padding: 0 0 100%;
            }

            .icon:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                margin-top: .25rem;
                border-bottom-color: transparent;
            }

            .icon:hover {
                cursor: pointer;
            }

            .showSearch {
                transition: transform .4s cubic-bezier(0.55, 0.06, 0.68, 0.19);
                transform: translateY(0);

                form {
                    position: relative;

                    input {
                        border: 0 none;
                        border-color: #d8d7d5;
                        border-bottom-width: 1px;
                        border-style: solid;
                        color: #212121;
                        background-color: #fff;
                        font-style: normal;
                        max-width: 100%;
                        width: 100%;
                        font-size: 1rem;
                        line-height: 19px;
                        padding-right: 3.125rem;
                        padding: .9375rem;
                        margin-bottom: 1px;
                        border-top-width: 1px;
                        position: relative;
                        border-style: dotted;
                    }

                    button {
                        padding: 0;
                        text-indent: -9999px;
                        position: absolute;
                        width: 3.125rem;
                        height: 3.125rem;
                        display: block;
                        top: 0;
                        right: 0;
                        background: transparent;
                        color: #212121;
                        white-space: nowrap;
                        z-index: 2;

                        span {
                            text-indent: -9999px;
                            display: block;
                            width: 8px;
                            height: 8px;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            position: absolute;
                            background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-right.svg);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            right: 0;
                        }
                    }
                }
            }

            .actives {
                transition: transform .4s cubic-bezier(.22, .61, .36, 1);
                transform: translateY(100%);
            }

        }

        .shopping-bag {
            position: relative;
            padding: 0 .5rem 0;

            .inner {
                display: inline-block;
                padding: 0 .5rem;
                z-index: 101;
                text-decoration: none;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                padding-bottom: .0625rem;
                border-bottom-color: transparent;

                .icon {
                    background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/bag.svg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    display: block;
                    height: 18px;
                    width: 18px;
                }

                .count {
                    bottom: .1rem;
                    font-size: .625rem;
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                }
            }
        }


    }
}

//购物弹框
.showBagDialog {
    ::v-deep .el-drawer {
        width: 100% !important;
    }

    ::v-deep .el-drawer__header {
        position: absolute !important;
        right: .49rem !important;
        top: .85rem;
        padding: 0;
        z-index: 23;
    }

    .shopping-bag-content {
        //padding: 0 1.1875rem;
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        height: calc(100% - 3.125rem - 3rem - 6.25rem);
        padding: 1.1875rem 1.1875rem 0;
        overflow-x: hidden;

        p {
            padding-top: 1.1875rem;
        }

        .shopping-bag-list {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;

            .shopping-bag-element {
                //align-items: flex-end;
                display: flex;
                position: relative;
                margin-bottom: 1.1875rem;

                .shopping-bag-element-image {
                    margin-right: .625rem;
                    min-width: 51%;
                    width: 51%;
                    position: relative;
                    z-index: 2;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .shopping-bag-element-info {
                    align-self: flex-end;
                    flex-grow: 2;
                    line-height: 1.5rem;
                    padding: 1.5rem 5% 1px 0;
                    margin-top: 3rem;

                    .element-price {
                        margin-bottom: 1.25rem;

                        .itemPrice {
                            display: inline-flex;
                        }
                    }

                    .removeBtn {
                        margin-top: 1rem;

                        button {
                            cursor: pointer;
                            text-decoration: none;
                            border: none;
                            line-height: 1;
                        }
                    }

                    .removeBtn:hover button {
                        border-bottom: 1px solid;
                    }
                }

            }

        }
    }

    .shopping-bag-footer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .prices {
            align-items: center;
            display: flex;
            //width: 100%;
            justify-content: space-between;
            height: 3.125rem;
            padding: 0 19px;
        }

        .goToCheckoutLink {
            background-color: #212121;
            border-bottom: 1px solid #e1e0de;
            border-top: 1px solid #e1e0de;
            color: #fff;
            display: block;
            padding: 1rem 1.1875rem .9375rem;
            position: relative;
            //width: 100%;
            border: 0;
            text-align: left;
            max-width: none;
        }

        .goToCheckoutLink:before {
            background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-right-white.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 50%;
            content: "";
            height: 8px;
            position: absolute;
            right: 1.4375rem;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
        }

        .goToCheckoutLink:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            margin-top: .25rem;
            border-bottom-color: transparent;
        }
    }

}

.showTranition {
    right: 0;
}

.shopping-bag-title {
    border-top: 1px solid #e1e0de;
    border-bottom: 1px solid #e1e0de;
    height: 3.125rem;
    justify-content: center;
    background: #ffffff;
    position: absolute;
    z-index: 22;
    width: 100%;

    a {
        border-bottom: 1px solid #212121;
        color: #212121;
        display: flex;
        padding-bottom: 0.625rem;
    }

    .counts {
        display: flex;
        flex-direction: row;
    }

    .counts:before {
        content: '('
    }

    .counts:after {
        content: ')'
    }
}

.loginDialog {
    ::v-deep .van-popup__close-icon {
        color: #212121;
        font-size: 16px;
    }
}

.loginBox {
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    background: #ffffff;

    h2 {
        left: 0;
        display: block;
        position: absolute;
        border-top: 1px solid #e1e0de;
        border-bottom: 1px solid #e1e0de;
        width: 100%;
        text-align: center;
        top: 0;
        height: 3.125rem;
        line-height: 3.125rem;
        background-color: #fff;
        z-index: 2;
    }

    .mfp-content-scrollable-area {
        width: 100%;
        height: calc(100% - 3.125rem - 3.125rem);
        display: block;
        position: relative;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin-top: 3.125rem;
    }

    .mfp-content-flex-item {
        width: 100%;
        margin: 0;
        display: flex;
        max-width: 100%;
    }

    .mfp-content-flex-item, .mfp-content-holder {
        height: auto;
    }

    .mfp-content-holder {
        padding: 0;
        width: 100%;
    }

    ::v-deep .van-button {
        background-color: #212121;
        border-width: 0;
        color: #fff;
        padding: 1rem 3.125rem .9375rem;
        text-align: center;
        width: 100%;
        margin-top: 2rem;
    }

    .login-page {
        padding-top: .9375rem;

        .login-container {
            margin: 0 1.1875rem;

            ::v-deep .van-cell {
                flex-direction: column;
                border: 0 none;
                border-bottom: 1px solid #d8d7d5;
                padding: 10px 0;
            }

            ::v-deep .van-cell:nth-child(2) {
                margin-top: 2rem;
            }

            ::v-deep .van-cell--required::before {
                display: none;
            }

            ::v-deep .van-field__error-message {
                position: absolute;
                top: 2rem;
            }

            .serverSideErrors {
                margin-top: 2rem;

                .mandatory {
                    margin-bottom: .5rem;
                }

                .consult {
                    margin-top: 1rem;

                    a {
                        color: inherit;
                        border-bottom: 1px solid;
                        padding-bottom: .0625rem;
                    }
                }
            }
        }
    }

    .registration-container {
        margin-top: 4rem;
        padding-bottom: 4rem;

        .registrBox {
            margin: 0 1.1875rem;

            .list-advantages {
                margin-top: 1.25rem;

                li {
                    position: relative;
                    padding-left: .625rem;
                    line-height: 1.2rem;

                }

                li:before {
                    content: "·";
                    margin-right: 3px;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

.registerBox {
    position: fixed;
    height: calc(100% - 3.125rem);
    left: 0;
    right: 0;
    background: #ffffff;

    .mfp-content {
        display: flex;
        flex-direction: column;
        background: #fff;
        position: relative;
        flex-grow: 1;
        //height: auto;
        height: 100%;
        padding-bottom: 2rem;
    }

    .title {
        left: 0;
        display: block;
        position: absolute;
        border-top: 1px solid #e1e0de;
        border-bottom: 1px solid #e1e0de;
        width: 100%;
        text-align: center;
        top: 0;
        height: 3.125rem;
        line-height: 3.125rem;
        background-color: #fff;
        z-index: 2;
    }

    .inner {
        width: 100%;
        height: 100%;
        max-height: 100%;
        margin-top: 3.125rem;
        position: relative;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: hidden;
        overflow-y: auto;
        display: block;

        .mfp-content-flex-item {
            width: 100%;
            max-width: 100%;
            height: auto;
            display: flex;

            .mfp-content-holder {
                width: 100%;
                height: auto;

                .registration-page {
                    padding-top: 2.1875rem;

                    h2 {
                        margin-bottom: 2.5rem;
                        margin: 0 1.1875rem;
                    }

                    .registration-wrapper {
                        color: #212121;

                        .registration-advantages-wrapper {
                            margin: 0 1.1875rem;

                            ul {
                                margin-top: 1rem;

                                li {
                                    position: relative;
                                    padding-left: .625rem;
                                    line-height: 1rem;
                                }

                                li:before {
                                    content: "·";
                                    margin-right: 3px;
                                    position: absolute;
                                    left: 0;
                                }
                            }
                        }

                        .form-wrapper {
                            margin: 0 1.1875rem;

                            .mandatory {
                                margin: 1.3125rem 0 1rem 0;
                            }

                            .datechose {
                                ::v-deep .el-form-item__content {
                                    display: flex;
                                    flex-grow: 1;
                                    justify-content: space-between;

                                    .el-select {
                                        padding-right: .8rem;
                                    }

                                    .el-select:last-child {
                                        padding-right: 0;
                                    }
                                }
                            }

                            a {
                                color: inherit;
                                position: relative;
                                text-decoration: none;
                                border-bottom-width: 1px;
                                border-bottom-style: solid;
                                padding-bottom: .0625rem;
                                border-color: inherit;
                            }

                            .requires {
                                margin: 1.3125rem 0 1rem 0;
                            }

                            ::v-deep .el-input__inner {
                                border: none;
                                padding: 0;
                                border-radius: 0;
                                height: 19px;
                                line-height: 19px;
                                font-size: 1rem;
                                border-bottom: 1px solid #d8d7d5;
                            }

                            .fieldRow {
                                margin: 2.9375rem 0 2.8125rem 0;
                            }

                            .privacyContainer {
                                margin-bottom: 2.75rem;
                            }

                            .acceptFlags {
                                margin-bottom: 1rem;
                                margin-top: 2rem;

                                ::v-deep .van-checkbox {
                                    margin-top: 1.375rem;
                                    position: relative;
                                }

                                ::v-deep .van-checkbox__icon {
                                    position: absolute;
                                    top: .48rem;
                                }

                                ::v-deep .van-checkbox__label {
                                    padding-left: 1rem;
                                }
                            }

                            .buttonsRow {
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: center;
                                margin-top: 2rem;

                                button {
                                    border-width: 0;
                                    color: #fff;
                                    display: block;
                                    padding: 1rem 3.125rem .9375rem;
                                    text-align: center;
                                    width: 100%;
                                    background-color: #212121;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.forgetPwdbox {
    ::v-deep .van-popup__close-icon {
        color: #212121;
        font-size: 16px;
    }

    .mfp-content {
        padding: 1.9375rem 2rem;
        margin-top: 6rem;

        form {
            margin: 0 -2rem;

            p {
                margin: 0 1.1875rem;
            }

            .require {
                margin: 1rem 1.1875rem;
            }

            .forVan {
                margin: 0 1.8175rem;
            }

            ::v-deep .van-button {
                height: 0;
                line-height: 0;
            }

            ::v-deep .van-cell {
                flex-direction: column;
                border: 0 none;
                border-bottom: 1px solid #d8d7d5;
                padding: 10px 0;
            }

            ::v-deep .van-cell--required::before {
                display: none;
            }

            ::v-deep .van-field__error-message {
                position: absolute;
                top: 2rem;
            }
        }

        .buttonsRow {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 0 1.1875rem;

        }

        button {
            border-width: 0;
            padding: 1rem 3.125rem .9375rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.5rem 0;
            margin-top: 2rem;
        }

        .submit {
            background-color: #212121;
            color: #fff;
        }

        .back {
            background-color: #e1e0de;
            color: #212121;
            margin-bottom: 2rem;
        }

    }
}

.back-holder {
    padding: 1.9375rem 2rem;

    .backForm {
        margin-top: 6rem;

        form {
            margin: 0 -2rem;

            p {
                margin: 0 1.1875rem;
            }

            .requireds {
                margin: 1rem 1.1875rem;
            }

            .forgets {
                margin-top: 1rem;
            }

            .const {
                margin: 2rem 1.1875rem;

                a {
                    color: inherit;
                    position: relative;
                    text-decoration: none;
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    padding-bottom: .0625rem;
                    border-bottom-color: currentColor;
                }
            }

            .rowBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 1.1875rem;
            }

            button {
                background-color: #212121;
                border-width: 0;
                color: #fff;
                padding: 1rem 3.125rem .9375rem;
                text-align: center;
                width: 100%;
                margin-top: 2rem;
            }

            .backFie {
                margin: 0 1.1875rem;
            }

            ::v-deep .van-cell {
                flex-direction: column;
                border: 0 none;
                border-bottom: 1px solid #d8d7d5;
                padding: 10px 0;
            }

            ::v-deep .van-cell:nth-child(2) {
                margin-top: 2rem;
            }

            ::v-deep .van-cell--required::before {
                display: none;
            }

            ::v-deep .van-field__error-message {
                position: absolute;
                top: 2rem;
            }
        }
    }

}

@media (min-width: 0) and (max-width: 1279.9px) {

}

@media (min-width: 0) and (max-width: 767.9px) {


}
</style>
