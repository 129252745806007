import request from "@/utils/request";

export function fetchCategories() {
    return request({
        url: "/web-site/product-categories",
        method: "get"
    });
}

export function fetchProductsList(parentId, subId, keyword, filter) {
    return request({
        url: `/web-site/products?parentCategoryId=${parentId}&subCategoryId=${subId}&keyword=${keyword}&filter=${filter}`,
        method: "get"
    });
}

export function fetchUserDefaultAddress() {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: `/web-site/auth/user/default-address`,
        method: "get"
    });
}

export function fetchUserInfo() {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: `/web-site/auth/user`,
        method: "get"
    });
}

export function fetchProductDetail(id) {
    return request({
        url: `/web-site/products/${id}`,
        method: "get"
    });
}

export function register(data) {
    return request({
        url: "/web-site/register",
        method: "post",
        data
    });
}

export function login(data) {
    return request({
        url: "/web-site/login",
        method: "post",
        data
    });
}

export function addToCart(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/carts",
        method: "post",
        data
    });
}

export function clearCart() {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/carts",
        method: "delete"
    });
}

export function removeCartItem(id) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/carts/" + id,
        method: "delete"
    });
}

export function removeAddress(id) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/addresses/" + id,
        method: "delete"
    });
}

export function editUserInfo(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/auth/user",
        method: "put",
        data
    });
}

export function editUserPassword(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/auth/edit-password",
        method: "post",
        data
    });
}

export function createOrder(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/orders",
        method: "post",
        data
    });
}

export function editUserEmail(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/auth/edit-email",
        method: "post",
        data
    });
}

export function getOrders(status) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/orders?status=" + status,
        method: "get"
    });
}

export function getCarts(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/carts",
        method: "get",
        data
    });
}

export function getAddress(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/addresses",
        method: "get",
        data
    });
}

export function addressAddSave(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: "/web-site/addresses",
        method: "post",
        data
    });
}

export function cancelOrder(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: `/web-site/orders/${data.item}/cancel`,
        method: "post",
        data
    });
}

export function deliveryOrder(data) {
    return request({
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        },
        url: `/web-site/orders/${data.item}/confirm-delivery`,
        method: "post",
        data
    });
}
