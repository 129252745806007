<template>

    <footer class="footer site-footer site-mover ready">
        <div class="site-footer-wrapper">
            <!-- 底部左侧-->
            <div class="site-footer-left">
                <ul class="site-footer-left-ul">
                    <li>
                        <span class="text">Shop in:</span>
                        <span class="value">IHong Kong, SAR of China</span>
                    </li>
                    <!--          <li>-->
                    <!--            <span>Contact Us</span>-->
                    <!--          </li>-->
                    <!--          <li>-->
                    <!--            <span class="text">Email Us</span>-->
                    <!--          </li>-->
                </ul>
            </div>
            <!-- 底部右侧-->
            <div class="site-footer-right">
                <!--        <ul class="site-footer-left-ul">-->
                <!--          <li>-->
                <!--            <a herf="#">About the brand</a>-->
                <!--          </li>-->
                <!--          <li>-->
                <!--            <a herf="#">Customer care</a>-->
                <!--          </li>-->
                <!--          <li>-->
                <!--            <a herf="#">Shipping</a>-->
                <!--          </li>-->
                <!--          <li>-->
                <!--            <a herf="#">Returns</a>-->
                <!--          </li>-->
                <!--          <li>-->
                <!--            <a herf="#">Legal</a>-->
                <!--          </li>-->
                <!--          <li>-->
                <!--            <a herf="#">Site map</a>-->
                <!--          </li>-->
                <!--          <li>-->
                <!--            <a herf="#">Instagram</a>-->
                <!--          </li>-->
                <!--        </ul>-->
                <p>
                    <span class="text">BING XU ©2021</span>
                </p>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    computed: {},
    methods: {}
};
</script>

<style scoped lang="scss">
.footer {
    padding: 2.5rem 4% 2.0625rem;
}

.site-footer-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.site-footer-left-ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
        padding: 0 .625rem;
        line-height: 1.4375rem;
        border-bottom: 1px solid #ffffff;
    }

    li:hover {
        cursor: pointer;
        border-bottom: 1px solid #212121;
        //padding-bottom: .125rem;
    }

}

.site-footer-right {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
}
</style>
