import axios from "axios";
import {Message} from "element-ui";
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        // console.log(config);
        // 如果需要在header里传token，则使用以下代码
        // const token = sessionStorage.getItem("accessToken");
        // config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const {status, data} = response;
        if (status >= 200 && status < 300) {
            return data;
        } else {
            return Promise.reject(new Error(data.respMsg || "Error"));
        }
    },

    error => {
        if (error.response.status === 401) {
            Message.error("You haven't signed in yet");
            return;
        } else if (error.response.status === 400) {
            Message.error(error.response.data.message);
            return;
        } else if (error.response.status === 500) {
            Message.error("System error");
            return;
        }
        return Promise.reject(error);
    }
);

export default service;
