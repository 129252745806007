<template>
    <footer class="mobileFooter">
        <ul>
            <li @click="showSelect">
                <button style="text-align: center;">
                    <!--          <span class="icon"></span>-->
                    <span class="content">Shop in:Hong Kong, SAR of China</span>
                </button>
            </li>
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Contact Us</span>-->
            <!--        </a>-->
            <!--      </li>-->
            <!--      <li @click="showNews">-->
            <!--        <button>-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Newsletter</span>-->
            <!--        </button>-->
            <!--      </li>-->
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Customer care</span>-->
            <!--        </a>-->
            <!--      </li>-->
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Shipping</span>-->
            <!--        </a>-->
            <!--      </li>-->
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Returns</span>-->
            <!--        </a>-->
            <!--      </li>-->
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Legal</span>-->
            <!--        </a>-->
            <!--      </li>-->
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Site map</span>-->
            <!--        </a>-->
            <!--      </li>-->
            <!--      <li>-->
            <!--        <a href="#">-->
            <!--          <span class="icon"></span>-->
            <!--          <span class="content">Instagram</span>-->
            <!--        </a>-->
            <!--      </li>-->
        </ul>
        <p class="copyright">
            <button>BING XU ©2021</button>
        </p>
        <!-- 弹框-->
        <div class="footerPop">
            <van-popup
                v-model="showPop"
                closeable
                close-icon-position="top-right"
                position="right"
                :overlay-style="{top:'3.125rem',height:'100%',maxHeight:'calc(100% - 3.125rem)',background:'none'}"
                :style="{width:'100%',height:'100%',marginTop:'3.125rem',background:'#fff'}">
                <div class="mfp-wrap">
                    <div class="mfp-container mfp-inline-holder">
                        <div class="mfp-content">
                            <section class="choose-your-country-wrapper">
                                <div class="chooseSection">
                                    <h2>Select Country</h2>
                                    <div class="chooseContent">
                                        <h3>Please select the country/region you wish to ship to:</h3>
                                        <div class="countries-select-wrapper">
                                            <el-select v-model="value" placeholder="请选择">
                                                <el-option
                                                    v-for="item in options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <p class="changing-country-warning">Important: Changing the country/region while
                                            shopping will
                                            remove all content from your shopping bag</p>
                                        <p class="international-site">International Site:</p>
                                        <ul class="other-nations">
                                            <li class="wx">
                                                <a href="#">
                                                    <span class="icon"></span>
                                                    <span class="text">English site</span>
                                                </a>
                                            </li>
                                            <li class="wx">
                                                <a href="#">
                                                    <span class="icon"></span>
                                                    <span class="text">Chinese site</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </section>

                        </div>
                    </div>
                </div>

            </van-popup>
            <!-- Newsletter弹框    -->
            <van-popup
                v-model="newsPop"
                closeable
                close-icon-position="top-right"
                position="bottom"
                :overlay-style="{background:'rgba(0,0,0,.13)'}"
                :style="{background:'#fff'}">
                <div class="newsletter-layer-content">
                    <div class="newsletter-box">
                        <h2>Subscribe to our Newsletter</h2>
                        <div class="newsletterForm ">
                            <van-form @submit="submitNews">
                                <van-field
                                    v-model="email"
                                    name="Email address"
                                    label="Email address *"
                                    label-align="top"
                                    input-align="left"
                                    label-width="100%"
                                    placeholder="Your email address"
                                    required
                                    :rules="[{pattern, message: '请输入正确内容' }]">
                                </van-field>
                                <div class="newsDetail">This site is protected by reCAPTCHA and the Google <a href="#">Privacy
                                    Policy</a> and <a href="#">Terms of Service</a> apply.
                                </div>
                                <div class="disclaimer">Please read the <a href="#">privacy policy</a> for further
                                    information.
                                </div>
                                <div class="btnRow">
                                    <van-button plain>Submit</van-button>
                                </div>
                            </van-form>

                        </div>
                    </div>
                </div>
            </van-popup>
        </div>
    </footer>
</template>
<script>
export default {
    name: "MobileFooter",
    data() {
        return {
            showPop: false,
            newsPop: false,
            email: "",
            pattern: /\d{6}/,
            options: [{
                value: "选项1",
                label: "黄金糕"
            }, {
                value: "选项2",
                label: "双皮奶"
            }, {
                value: "选项3",
                label: "蚵仔煎"
            }],
            value: ""
        };
    },
    computed: {},
    methods: {
        submitNews() {
        },
        showSelect() {
            this.showPop = false;
        },
        // Newsletter
        showNews() {
            this.newsPop = false;
        }
    }
};
</script>

<style scoped lang="scss">
button {
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;

    &:focus {
        outline: 0;
    }
}

h2, h3 {
    font-size: 1em;
    margin: 0;
    font-weight: 400;
    line-height: 1.1875rem;
}

a {
    text-decoration: none;
    color: #212121;
}

::v-deep .van-popup__close-icon {
    color: #212121;
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0;
}

.footerPop {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    ::v-deep .van-popup {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    .mfp-content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
}

.mobileFooter {
    display: flex;
    flex-direction: column;
    //position: relative;
    background: #fff;
    z-index: 1;
    margin-top: 4rem;

    li {
        position: relative;

        a, button {
            background-color: #fff;
            border-bottom: 1px solid #e1e0de;
            border-top: 1px solid #e1e0de;
            color: #212121;
            display: block;
            padding: 1rem 1.1875rem .9375rem;
            width: 100%;
            border-bottom: 0 none;
            text-align: inherit;

            .content {
                text-decoration: none;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                padding-bottom: .0625rem;
                border-bottom-color: transparent;
                display: inline;
                text-align: left;
            }

            .icon {
                background-image: url(https://www.therow.com/ytos/resources/THEROW/svg/arrow-right.svg);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: 50%;
                content: "";
                height: 8px;
                position: absolute;
                right: 1.4375rem;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
            }
        }
    }

    li:last-child a {
        border-bottom: 1px solid #e1e0de;
    }

    p.copyright {
        text-align: center;

    }

}

//弹框
.mfp-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .mfp-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-bottom: 3rem;
        overflow: hidden;
    }

    .choose-your-country-wrapper {
        margin: 0;
        padding: 0;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }

    .chooseSection {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    h2 {
        text-align: center;
        padding-top: .9375rem;
    }

    .chooseContent {
        h3, .countries-select-wrapper, .changing-country-warning, .international-site {
            padding: 0 1.1875rem;
        }

        h3 {
            font-size: .8125rem;
        }

        .countries-select-wrapper {
            margin-top: 1rem;

            .el-select {
                width: 100%;

                ::v-deep .el-input__inner {
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    border-bottom: 1px solid #d8d7d5;
                }
            }
        }

        .changing-country-warning {
            padding-bottom: 5.3125rem;
        }
    }
}

.newsletter-layer-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    h2 {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 3.3125rem;
        border-bottom: 1px solid #d8d7d5;
        padding: 0 2.625rem;
        font-size: .8125rem;
    }

    .newsletter-box, .newsletterForm, form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .newsletter-box {
        .newsletterForm {
            margin: 0 1.1875rem;

            form {
                padding: 1.25rem 0;
                padding-top: 1.6875rem;

                a {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    padding-bottom: .0625rem;
                    border-color: inherit;
                }

                ::v-deep .van-cell {
                    flex-direction: column;
                    border: 0 none;
                    border-bottom: 1px solid #d8d7d5;
                    padding: 0;
                }

                ::v-deep .van-field__error-message {
                    position: absolute;
                    top: 2rem;
                }

                ::v-deep .van-cell--required:before {
                    display: none;
                }

                .newsDetail {
                    margin-bottom: 3.9375rem;
                    margin-top: 2rem;
                }

                .disclaimer {
                    margin-bottom: 1.5rem;
                }

                .btnRow {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    button {
                        background-color: #212121;
                        border-width: 0;
                        color: #fff;
                        display: block;
                        padding: 1rem 3.125rem .9375rem;
                        text-align: center;
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
    }

}
</style>
