import {fetchUserInfo} from "@/api/api";

const state = {
    info: {}
};

const mutations = {
    SET_INFO: (state, info) => {
        state.info = info;
    }
};

const actions = {
    getInfo({commit}) {
        return new Promise((resolve, reject) => {
            fetchUserInfo().then(response => {
                const {data} = response;

                if (!data) {
                    reject("Verification failed, please Login again.");
                }

                const {detail} = data;

                commit("SET_INFO", detail);
                resolve(detail);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
