<template>
    <header class="header site-header ready header-sticky-native">
        <div class="site-header-inner">
            <div class="logo-wrapper">
                <router-link :to="{ path: '/' }">
                    <div class="logo">
                        <span>BING XU</span>
                    </div>
                </router-link>
            </div>
            <div class="site-header-row">
                <div class="mainMenu main-menu ready">
                    <div class="main-menu-wrapper">
                        <div class="main-menu-inner">
                            <div class="level-0 main-menu-level main-menu-level-0">
                                <ul class="main-menu-list">
                                    <li
                                        :class="
                      `${
                        menu.id === currentSelectedCategoryId ? 'selected' : ''
                      } hasChildren shop_women menuItem main-menu-item`
                    "
                                        v-for="menu in categories"
                                        :key="menu.id"
                                        @click="() => toggleMenuStatus(menu)"
                                    >
                                        <button>
                                            <span class="text">{{ menu.name.en }}</span>
                                        </button>
                                        <div
                                            class="level-1 main-menu-level main-menu-level-1"
                                            v-if="menu.subCategories !== undefined"
                                        >
                                            <ul class="main-menu-list">
                                                <li
                                                    class="main-menu-item"
                                                    v-for="subCategory in menu.subCategories"
                                                    :key="subCategory.id"
                                                    @click="toShopList(menu, subCategory.id)"
                                                >
                                                    <span>{{ subCategory.name.en }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="main-menu-nav"></ul>
            </div>
            <div class="right_wrapper">
                <ul class="topUs">
                    <li>
                        <form class="search">
                            <input
                                placeholder="Please enter the contents"
                                ref="ipt"
                                v-if="ipt"
                                v-model="iptSearch"
                                @blur="iptBlur()"
                                autofocus="autofocus"
                            />
                            <span @click="searchButton"> Search</span>
                        </form>
                    </li>
                    <li v-show="Object.keys(userInfo).length">
            <span
            ><router-link :to="{ path: '/myAccount' }"
            >My Account</router-link
            ></span
            >
                    </li>
                    <li v-show="!Object.keys(userInfo).length">
                        <span @click="showPopup">Login</span>
                    </li>
                    <li>
            <span @click="showShop"
            >Shopping bag({{
                    Object.keys(userInfo).length
                        ? userInfo.cartProductQuantity
                        : "0"
                }})</span
            >
                    </li>
                </ul>
                <div class="dialogBoxs">
                    <el-drawer
                        :visible.sync="showContent"
                        :modal="false"
                        size="44%"
                        :direction="direction"
                    >
                        <!-- 登录框-->
                        <div class="loginDialog" v-if="status == 1">
                            <div>Login</div>
                            <el-form
                                ref="loginForm"
                                :rules="rules"
                                :label-position="labelPosition"
                                :model="formLabelAlign"
                                class="demo-ruleForm"
                                size="small"
                                :hide-required-asterisk="true"
                            >
                                <el-form-item label="Email address *" prop="email">
                                    <el-input
                                        v-model="formLabelAlign.email"
                                        placeholder="Your email address"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="Password *" prop="password">
                                    <el-input
                                        v-model="formLabelAlign.password"
                                        placeholder="Password"
                                        type="password"
                                    ></el-input>
                                </el-form-item>
                            </el-form>
                            <button
                                class="submit loginSub"
                                @click="confirmLogin('loginForm')"
                                :plain="true"
                            >
                                Login
                            </button>

                            <div>* Required fields</div>
                            <div class="forgetPwd">
                                <button @click="forgetpwdFn">
                                    <span>Forgot your password?</span>
                                </button>
                            </div>
                            <div>
                                Consult our
                                <router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link>
                                for further information.
                            </div>
                            <div class="registBox">
                                <h2>Registration</h2>
                                <p>
                                    Register now and make the most of My Account. You will be able
                                    to:
                                </p>
                                <ul>
                                    <li>Receive our exclusive newsletter</li>
                                    <li>Save your favorite items</li>
                                    <li>Shop faster</li>
                                    <li>Check your orders and returns</li>
                                </ul>
                                <div class="registBtn">
                                    <button class="submit registSub" @click="registFn">
                                        Register
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--  注册-->
                        <div class="registerMes loginDialog" v-if="status == 2">
                            <el-form
                                ref="registerForm"
                                :rules="registerRules"
                                :label-position="labelPosition"
                                :model="registerForm"
                                class="demo-ruleForm"
                                size="small"
                                :hide-required-asterisk="true"
                            >
                                <div>Registration</div>
                                <p>
                                    Register now and make the most of My Account. You will be able
                                    to:
                                </p>
                                <ul>
                                    <li>Receive our exclusive newsletter</li>
                                    <li>Save your favorite items</li>
                                    <li>Shop faster</li>
                                    <li>Check your orders and returns</li>
                                </ul>
                                <div class="requires">* Required fields</div>
                                <el-form-item label="First Name *" prop="firstName">
                                    <el-input
                                        v-model="registerForm.firstName"
                                        placeholder="First Name"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="Last Name *" prop="lastName">
                                    <el-input
                                        v-model="registerForm.lastName"
                                        placeholder="Last Name"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="Email address *" prop="email">
                                    <el-input
                                        v-model="registerForm.email"
                                        placeholder="Your email address"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="Password *(6 - 15 characters)"
                                    prop="password"
                                >
                                    <el-input
                                        v-model="registerForm.password"
                                        placeholder="Password"
                                        type="password"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="Confirm Password *" prop="confirmPassword">
                                    <el-input
                                        v-model="registerForm.confirmPassword"
                                        placeholder="Confirm Password"
                                        type="password"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="Date of birth"
                                    prop="confPass"
                                    class="dateBirth"
                                >
                                    <div class="block">
                                        <el-date-picker
                                            v-model="dateValue"
                                            type="date"
                                            placeholder="Date of birth"
                                            class="dateEletion"
                                        >
                                        </el-date-picker>
                                    </div>
                                </el-form-item>
                                <div class="pargs">
                                    This site is protected by reCAPTCHA and the Google
                                    <a href="#">Privacy Policy</a> and
                                    <a href="#">Terms of Service apply.</a>
                                </div>
                                <div class="detail">
                                    In order to enhance your experience on bingxu.com, personal
                                    data will be electronically processed by BING XU. Your data
                                    will be strictly controlled by BING XU for customer
                                    management, marketing and other purposes as detailed in our
                                    <a href="#">Privacy Policy.</a>
                                </div>
                                <div class="agrment">
                                    <el-checkbox-group v-model="checkList">
                                        <el-checkbox
                                            label="I agree that BING XU may collect my personal information for marketing purposes (newsletters, updates and collection arrivals etc.)."
                                        ></el-checkbox>
                                        <el-checkbox
                                            label="I consent to the processing of my personal data by BING XU for customer satisfaction purposes and for customizing my user experience to my interests or my shopping habits."
                                        ></el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-form>
                            <div class="registBtn">
                                <button class="submit registerSub" @click="confirmRegister('registerForm')">
                                    Register
                                </button>
                            </div>
                        </div>
                        <div class="showBags" v-if="status == 3">
                            <div class="showLf">
                                <a href="#"
                                >Shopping Bag({{
                                        Object.keys(userInfo).length
                                            ? userInfo.cartProductQuantity
                                            : "0"
                                    }})</a
                                >
                            </div>
                            <div class="shopping-bag-content ">
                                <div
                                    class="customScrollbar shopping-bag-list ready ps ps--active-y"
                                >
                                    <div
                                        class="shopping-bag-list-product"
                                        v-if="Object.keys(userInfo).length && userInfo.carts.list"
                                    >
                                        <div
                                            :key="index"
                                            v-for="(item, index) in userInfo.carts.list"
                                            class="shopping-bag-element"
                                        >
                                            <div class="shopping-bag-element-image">
                                                <img :src="item.productThumbnail"/>
                                            </div>
                                            <div class="shopping-bag-element-info">
                                                <div class="element-info element-title">
                                                    <span>{{ item.productName }}</span>
                                                </div>
                                                <div class="element-info element-price">
                                                    <div class="itemPrice">
                                                        <span>HK$ {{ fenToYuan(item.amount) }}</span>
                                                    </div>
                                                </div>
                                                <div v-if="item.pack">
                                                    <span>Pack: {{ item.pack.name }}</span>
                                                </div>
                                                <div class="element-info element-size-color">
                                                    <div
                                                        :key="k"
                                                        v-for="(sku, k) in item.productSku"
                                                        class="element-size"
                                                    >
                                                        {{ k }}: {{ sku }}
                                                    </div>
                                                    <div>Quantity: {{ item.quantity }}</div>
                                                </div>
                                                <div class="removeBtn">
                                                    <button @click="removeCartItem(item.id)">
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="shopping-bag-footer">
                                <div class="prices products-price">
                                    <span class="label">Subtotal:</span>
                                    <span class="valueToPay"
                                    >HK$
                    {{
                                            Object.keys(userInfo).length
                                                ? fenToYuan(userInfo.carts.subTotal)
                                                : "0.00"
                                        }}</span
                                    >
                                </div>
                                <router-link
                                    :to="{ path: '/checkout' }"
                                    class="goToCheckoutLink"
                                    v-if="Object.keys(userInfo).length && userInfo.carts.list"
                                >
                                    <button style="width: 100%;" @click="closeDrawer">
                                        Proceed to checkout
                                    </button>
                                </router-link>
                            </div>
                            <!--              <div class="showRf">No items</div>-->
                        </div>
                    </el-drawer>
                    <!--          忘记密码-->
                    <el-drawer
                        :visible.sync="forgetDrawer"
                        :modal="false"
                        :direction="forgetDir"
                        class="forgetPwd"
                    >
                        <div class="forgetLogin" v-if="isLogin == 11">
                            <el-form
                                :rules="registerRules"
                                :label-position="forgetPosition"
                                :model="formLabelAligns"
                                class="demo-ruleForm"
                                size="small"
                                :hide-required-asterisk="true"
                            >
                                <p>
                                    Enter the email address you use for My Account and you will
                                    receive an email containing a link to change your password.
                                </p>
                                <div class="requires">* Required fields</div>
                                <el-form-item
                                    label="Email address *"
                                    prop="region"
                                    style="color:#820000"
                                >
                                    <el-input
                                        v-model="formLabelAligns.region"
                                        placeholder="Your email address"
                                    ></el-input>
                                </el-form-item>
                                <div class="btn">
                                    <button class="send">Send</button>
                                    <button class="back" @click="backLogin">Back to login</button>
                                </div>
                            </el-form>
                        </div>
                        <!--  回到登录-->
                        <div class="backLogins forgetLogin" v-if="isLogin == 22">
                            <el-form
                                :rules="backRule"
                                :label-position="forgetPosition"
                                :model="backForm"
                                class="demo-ruleForm"
                                size="small"
                                :hide-required-asterisk="true"
                            >
                                <p>Log in with your email and password.</p>
                                <div class="requires">* Required fields</div>
                                <el-form-item
                                    label="Email address *"
                                    prop="address"
                                    style="color:#820000"
                                >
                                    <el-input
                                        v-model="backForm.address"
                                        placeholder="Your email address"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="Password *"
                                    prop="password"
                                    style="color:#820000"
                                >
                                    <el-input
                                        v-model="backForm.password"
                                        placeholder="Password"
                                    ></el-input>
                                </el-form-item>
                                <div class="backFor" @click="backForget">
                                    <a href="#">Forgot your password?</a>
                                </div>
                                <div class="backInfo">
                  <span
                  >Consult our<a href="#">Privacy Policy</a>for further
                    information</span
                  >
                                </div>
                                <div class="btn">
                                    <button class="send">Login</button>
                                </div>
                            </el-form>
                        </div>
                    </el-drawer>
                </div>
            </div>
        </div>
    </header>
    <!--  当屏幕小于768px-->
</template>
<script>
// import {f} from "@/utils/util";
import {login, register, removeCartItem} from "@/api/api";
import {priceTransform} from "@/utils/util";
import {EventBus} from "../utils/event-bus.js";

export default {
    name: "Header",
    data() {
        return {
            dateValue: "",
            currentSelectedCategoryId: null,
            ipt: false,
            iptSearch: "",
            showContent: false,
            forgetDrawer: false,
            registDrawer: false,
            accessToken: "",
            direction: "rtl",
            forgetDir: "btt",
            regDirections: "rtl",
            labelPosition: "top",
            forgetPosition: "top",
            status: 2, //默认是登录弹窗
            isLogin: 11, //默认是修改密码窗口
            value: "",
            // 登陆表单
            formLabelAlign: {
                email: "",
                password: ""
            },
            formLabelAligns: {
                region: ""
            },
            // 返回登录
            backForm: {
                address: "",
                password: ""
            },
            registerForm: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                birthYear: "",
                birthMonth: "",
                birthDay: ""
            },
            checkList: [],
            registerRules: {
                firstName: [
                    {
                        required: true,
                        message: "The first name field is required",
                        trigger: "blur"
                    }
                ],
                lastName: [
                    {
                        required: true,
                        message: "The last name field is required",
                        trigger: "blur"
                    }
                ],
                email: [
                    {
                        type: "email",
                        required: true,
                        message: "The email address field is required",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "The password field is required",
                        trigger: "blur"
                    }
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: "The confirm password field is required",
                        trigger: "blur"
                    }
                ]
            },
            rules: {
                email: [
                    {
                        required: true,
                        type: 'email',
                        message: "The email address field is required",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "The password field is required",
                        trigger: "blur"
                    }
                ]
            },
            // 忘记密码
            regRules: {
                name: [
                    {
                        required: true,
                        message: "The email address field is required",
                        trigger: "blur"
                    }
                ],
                region: [
                    {
                        required: true,
                        message: "The password field is required",
                        trigger: "blur"
                    }
                ]
            },
            // 返回登录
            backRule: {
                email: [
                    {
                        required: true,
                        message: "The email address field is required",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "The password field is required",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    mounted() {
        this.accessToken = sessionStorage.getItem("accessToken");
        console.log(this.accessToken);

        //监听打开购物车事件
        EventBus.$off("openPCShoppingBagEvent");
        EventBus.$on("openPCShoppingBagEvent", () => {
            this.showShop();
        });
    },
    props: {
        categories: Array,
        userInfo: Object
    },
    computed: {},
    methods: {
        fenToYuan(fen) {
            return priceTransform(fen);
        },
        async removeCartItem(id) {
            const response = await removeCartItem(id);
            console.log(response);
            if (response.code === 0) {
                this.$message({
                    showClose: true,
                    message: "Success",
                    type: "success"
                });
                this.$parent.fetchUserInfo();
            } else {
                this.$message({
                    showClose: true,
                    message: response.message,
                    type: "error"
                });
            }
        },
        toShopList(menu, subId) {
            this.pareId = menu.id;
            window.location.href =
                "/shop?parentCategoryId=" + this.pareId + "&subCategoryId=" + subId;
        },
        toggleMenuStatus(menu) {
            if (this.currentSelectedCategoryId === menu.id) {
                this.currentSelectedCategoryId = null;
            } else {
                this.currentSelectedCategoryId = menu.id;
            }
            if (menu.subCategories === undefined) {
                window.location.href =
                    "/shop?parentCategoryId=" + menu.id + "&subCategoryId=" + "";
            }
        },
        // 控制输入框的显示与隐藏的处理函数
        searchButton() {
            this.ipt = !this.ipt;
            this.$nextTick(() => {
                this.$refs.ipt.focus();
            });
        },
        // 输入框失去焦点的处理函数
        iptBlur() {
            this.ipt = false;
            window.location.href = "/shop?keyword=" + this.iptSearch;
        },
        // 登录
        showPopup() {
            this.status = 1;
            this.showContent = true;
        },
        // 忘记密码
        forgetpwdFn() {
            this.isLogin = 11;
            this.forgetDrawer = true;
            this.showContent = false;
        },
        registFn() {
            this.status = 2;
            this.registDrawer = true;
        },
        //展示所买商品
        showShop() {
            this.status = 3;
            this.showContent = true;
        },
        closeDrawer() {
            this.showContent = false;
        },
        //修改完密码 返回登录
        backLogin() {
            this.isLogin = 22;
        },
        //忘记密码
        backForget() {
            this.isLogin = 11;
        },
        async confirmRegister(formName) {
            if (this.checkList.length < 2) {
                this.$message({
                    showClose: true,
                    message: 'Please check to agree to the relevant agreement',
                    type: "error"
                });
                return;
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.dateValue) {
                        this.registerForm.birthYear = this.$moment(this.dateValue).format("YYYY");
                        this.registerForm.birthMonth = this.$moment(this.dateValue).format("MM");
                        this.registerForm.birthDay = this.$moment(this.dateValue).format("DD");
                    }
                    const {
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                        birthDay,
                        birthMonth,
                        birthYear
                    } = this.registerForm;
                    const res = await register({
                        firstName,
                        lastName,
                        email,
                        password,
                        confirmPassword,
                        birthDay,
                        birthMonth,
                        birthYear
                    });
                    console.log(res);
                    const {code, message} = res;
                    if (code !== 0) {
                        console.log(message);
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            message: "Registered successful",
                            type: "success"
                        });
                        this.showContent = false;
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        async confirmLogin(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const {email, password} = this.formLabelAlign;
                    const res = await login({email, password});
                    const {code, data, message} = res;
                    if (code !== 0) {
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        this.showContent = false;
                        const {accessToken} = data;
                        this.accessToken = accessToken;
                        sessionStorage.setItem("accessToken", accessToken);

                        this.$parent.fetchUserInfo();

                        this.$message({
                            showClose: true,
                            message: "Login successful",
                            type: "success"
                        });

                        this.showContent = false;
                        if (this.$route.path === "/login") {
                            this.$router.push({path: "/overView"});
                        }
                    }
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep .dateEletion .el-input__prefix {
    left: -5px;
}

::v-deep .dateEletion .el-input__inner {
    margin-left: 1.5rem;
    width: 10rem;
}

button {
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;

    &:focus {
        outline: 0;
    }
}

.header-sticky-native {
    position: -webkit-sticky;
    position: sticky;
    height: 6.875rem;
    z-index: 100;
    left: 0;
    top: 0;
    background: #ffffff;

    & > .site-header-inner {
        & > .logo-wrapper {
        }

        & > .site-header-row {
            display: flex;
            align-items: center;
            position: relative;
            height: 3.125rem;
            top: 0;
            left: 0;
            //width: 100%;
            padding: 0 4%;

            .mainMenu {
                padding-top: 2rem;
            }
        }
    }

    .text {
        text-decoration: none;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding-bottom: 0.0625rem;
        border-bottom-color: transparent;
    }

    a {
        color: #333;
        text-decoration: none;
    }

    .main-menu-list {
        display: flex;
        align-items: flex-start;

        & > .main-menu-item {
            li {
                border-bottom: 1px solid #ffffff;
            }

            li:hover {
                cursor: pointer;
                border-bottom: 1px solid #212121;
            }

            &.selected {
                .text {
                    border-bottom-style: dotted;
                    border-bottom-color: #000;
                }

                & > .main-menu-level-1 {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 0s cubic-bezier(0.22, 0.61, 0.36, 1) 0s,
                    opacity 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
                }
            }

            &:hover {
                span {
                    cursor: pointer;
                    border-bottom-color: #000;
                }
            }

            .main-menu-level-1 {
                height: 176px;
                visibility: hidden;
                opacity: 1;
                transition: visibility 0s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0.2s,
                opacity 0.2s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s;
                overflow: hidden;
                padding-right: 2.125rem;
                position: absolute;
                padding-left: 0.5rem;
                padding-top: 0.5rem;
                margin-left: -0.5rem;
                margin-top: -0.5rem;
                z-index: 103;
                height: calc(100vh - 6.875rem + 1.1875rem + 0.5rem);
                min-height: calc(100vh - 6.875rem + 1.1875rem + 0.5rem);
                top: calc(100% - 1.1875rem);
                background: hsla(0, 0%, 100%, 0.9);
                box-shadow: -9999px 9999px 0 9999px hsl(0deg 0% 100% / 90%);

                & > .main-menu-list {
                    flex-direction: column;
                    align-items: flex-start;

                    & > .main-menu-item {
                        margin-right: 0;
                        margin-bottom: 0.25rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 1280px) {
    .active-span:hover {
        padding-bottom: 0.17rem;
        border-bottom: 1px solid #212121;
    }
    .header-sticky-native {
        & > .site-header-inner {
            & > .logo-wrapper {
                left: 50%;
                margin: 0;
                position: absolute;
                top: 35%;
                transform: translate(-50%, -50%);
                z-index: 2;

                .logo {
                    width: 100px;
                    height: 13px;
                    background-image: url(../assets/logo.png);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    display: block;

                    span {
                        clip: rect(1px, 1px, 1px, 1px);
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        border: 0;
                        padding: 0;
                        overflow: hidden;
                    }
                }
            }

            & > .site-header-row {
                height: 6.875rem;

                li {
                    padding: 0.24rem 0.625rem;
                    //padding: 0 0.625rem;
                }
            }
        }

        .main-menu-list {
            & > .main-menu-item {
                &.selected {
                    & > .main-menu-level-1 {
                    }
                }

                .main-menu-level-1 {
                }
            }
        }

        .logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .right_wrapper {
            position: absolute;
            top: 58%;
            padding: 0 4%;
            right: 0;

            .topUs {
                li {
                    cursor: pointer;
                }

                li:nth-child(2):hover {
                    border-bottom: 1px solid;
                    padding-bottom: 0.125rem;
                }

                li:nth-child(3):hover {
                    border-bottom: 1px solid;
                    padding-bottom: 0.125rem;
                }
            }

            ::v-deep .el-drawer {
                overflow-y: auto;
            }

            ::v-deep input {
                border: none;
                padding: 0;
                border-radius: 0;
                border-bottom: 1px dashed;
            }

            #myDate {
                ::v-deep input {
                    border-bottom: 1px solid;
                    margin-left: 1px;
                }
            }

            ::v-deep .el-form-item__error {
                padding-top: 0.55rem;
            }

            .loginDs {
                ::v-deep .el-form-item__label {
                    padding: 0;
                    line-height: 0;
                }

                ::v-deep .el-form-item {
                    margin-top: 2rem;
                    padding-bottom: 2rem;
                }
            }

            .registerMes {
                ::v-deep .el-form-item {
                    margin-bottom: 2.5rem;
                }

                ::v-deep .el-form-item__label {
                    line-height: 0;
                }

                .dateBirth {
                    ::v-deep .el-form-item__content {
                        display: flex;
                        flex-grow: 1;
                        justify-content: flex-start;

                        .el-select {
                            padding-right: 0.8rem;
                        }

                        .el-select:last-child {
                            padding-right: 0;
                        }
                    }
                }

                .pargs {
                    margin: 2.9375rem 0 2.8125rem 0;
                    font-size: 0.8125rem;
                    line-height: 1.1875rem;
                }

                .detail {
                    margin-bottom: 0.75rem;
                    font-size: 0.8125rem;
                    line-height: 1.1875rem;
                }

                .agrment {
                    margin-bottom: 1rem;
                }

                ::v-deep .el-checkbox__label {
                    color: #212121;
                    font-size: 0.8125rem;
                    font-weight: 400;
                    font-style: normal;
                    white-space: normal;
                    word-break: break-all;
                }

                ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
                    display: none;
                }

                ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: #212121;
                    font-size: 0.8125rem;
                    font-weight: 400;
                    font-style: normal;
                    white-space: normal;
                    word-break: break-all;
                }

                ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
                    border-color: #dcdfe6;
                }

                ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
                    background: #212121;
                    border: none;
                }

                ::v-deep .el-checkbox__input {
                    vertical-align: top;
                    line-height: 2;
                }

                ::v-deep .el-checkbox {
                    margin-bottom: 1rem;
                    padding: 0 1rem;
                }
            }

            .loginDialog {
                width: 27.5rem;
                margin-top: 2rem;
                margin-left: auto;
                margin-right: auto;

                a {
                    border-bottom: 1px solid;
                    padding-bottom: 0.125rem;
                }

                .requires {
                    margin: 1rem 0;
                }

                .submit {
                    background-color: #212121;
                    border-width: 0;
                    color: #fff;
                    display: block;
                    padding: 1rem 3.125rem 0.9375rem;
                    text-align: center;
                    width: 80%;
                    margin: 0 0 3rem 0;
                }

                .loginSub:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }

                .registSub:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }

                .registerSub:hover {
                    cursor: pointer;
                    opacity: 0.8;
                }

                ::v-deep .el-form-item__label {
                    padding: 0;
                    line-height: 0;
                }

                ::v-deep .el-form-item {
                    margin-top: 2rem;
                    padding-bottom: 2rem;
                }
            }

            .registerMes {
                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        margin-left: 0;
                        flex-direction: row;
                        font-size: 0.8125rem;
                        line-height: 1.1875rem;
                        letter-spacing: 0.01562rem;
                    }

                    li:before {
                        content: "·";
                        margin-right: 3px;
                    }
                }
            }

            //忘记密码表单
            .forgetLogin {
                width: 30rem;
                padding: 3.9375rem calc(4% + 0.8125rem);
                margin: 0 auto;

                p {
                    line-height: 1.1875rem;
                }

                .btn {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    justify-content: center;

                    button {
                        max-width: 18.75rem;
                        padding: 0.3125rem 0.3125rem 0.375rem;
                        width: 100%;
                        margin-left: 0.3125rem;
                        margin-right: 0.3125rem;
                        margin-top: 1rem;
                    }

                    .send {
                        color: #fff;
                        background: #212121;
                    }

                    .send:hover {
                        cursor: pointer;
                        opacity: 0.8;
                    }

                    .back {
                        color: #212121;
                        background: #e1e0de;
                    }

                    .back:hover {
                        background: #b7b6b1;
                    }
                }

                ::v-deep .el-form-item__label {
                    padding: 0;
                    line-height: 0;
                }

                ::v-deep .el-form-item {
                    margin-top: 2rem;
                    padding-bottom: 2rem;
                }
            }

            .backLogins {
                .backFor,
                .backInfo {
                    margin-bottom: 2rem;

                    span {
                        a {
                            border-bottom: 1px solid;
                            padding-bottom: 0.125rem;
                        }
                    }
                }

                .backFor a:hover {
                    cursor: pointer;
                    border-bottom: 1px solid;
                    padding-bottom: 0.125rem;
                }
            }

            .forgetPwd {
                margin: 0.25rem 0 1.5rem 0;

                ::v-deep .btt {
                    min-height: 82vh;
                }

                &:hover {
                    span {
                        cursor: pointer;
                        border-bottom: 1px solid;
                        padding-bottom: 0.125rem;
                    }
                }
            }

            ul {
                display: flex;

                li {
                    margin-left: 1.25rem;
                }
            }

            .registBox {
                padding-top: 3rem;

                h2 {
                    margin-bottom: 2.65rem;
                    font-size: 1em;
                    font-weight: 400;
                }

                p {
                    font-size: 0.8125rem;
                }

                ul {
                    flex-direction: column;

                    li {
                        margin-left: 0;
                        flex-direction: row;
                        font-size: 0.8125rem;
                        line-height: 1.1875rem;
                        letter-spacing: 0.01562rem;
                    }

                    li:before {
                        content: "·";
                        margin-right: 3px;
                    }
                }

                .registBtn {
                    margin-top: 2rem;
                }
            }

            .showBags {
                padding: 2rem 12% 9.1875rem;

                .showLf {
                    margin-bottom: 1.75rem;
                }

                .showRf {
                    text-align: right;
                    margin-top: 1.75rem;
                }
            }

            .search {
                display: flex;

                input {
                    border: none;
                    outline: medium;
                    border-bottom: 1px solid #212121;
                    width: 18rem;
                    margin-right: 5px;
                    cursor: pointer;
                }

                span:hover {
                    white-space: nowrap;
                    border-bottom: 1px solid #212121;
                    vertical-align: bottom;
                }
            }
        }

        .right_wrapper .text {
            font: 400 14px Arial;
        }

        .shopping-bag-content {
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden;
            height: calc(100% - 3.125rem - 1.75rem - 7.5625rem);

            .shopping-bag-list {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
                position: relative;

                .shopping-bag-element {
                    align-items: flex-start;
                    display: flex;
                    position: relative;
                    margin-bottom: 1.9375rem;

                    .shopping-bag-element-image {
                        position: relative;
                        margin-right: 5%;
                        min-width: 30%;
                        width: 30%;
                        z-index: 2;

                        img {
                            //top: 0;
                            //left: 0;
                            //position: absolute;
                            width: 100%;
                        }
                    }

                    .shopping-bag-element-info {
                        align-self: flex-end;
                        flex-grow: 2;
                        line-height: 1.6rem;
                        padding: 1.5rem 5% 1px 0;

                        .element-price {
                            margin-bottom: 1.25rem;

                            .itemPrice {
                                display: inline-flex;
                            }
                        }

                        .removeBtn {
                            margin-top: 1rem;

                            button {
                                cursor: pointer;
                                text-decoration: none;
                                border: none;
                                line-height: 1;
                            }
                        }

                        .removeBtn:hover button {
                            border-bottom: 1px solid;
                        }
                    }
                }
            }
        }

        .shopping-bag-footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 7.5625rem;
            justify-content: flex-end;

            .prices {
                align-items: center;
                display: flex;
                width: 100%;
                justify-content: space-between;
                height: 3.375rem;
            }

            .goToCheckoutLink {
                background-color: #212121;
                border-width: 0;
                color: #fff;
                display: block;
                padding: 0.3125rem 0.3125rem 0.375rem;
                text-align: center;
                width: 100%;
            }

            a button:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 768px) {
    .header-sticky-native {
        & > .site-header-inner {
            & > .logo-wrapper {
            }

            & > .site-header-row {
                height: 6.875rem;
            }
        }

        .main-menu-list {
            & > .main-menu-item {
                &.selected {
                    & > .main-menu-level-1 {
                    }
                }

                .main-menu-level-1 {
                }
            }
        }
    }
}
</style>
