<template>
    <div id="app" >
        <div class="pc">
            <div>
                <Header v-show="!is404" :categories="categories" :userInfo="userInfo"/>
                <router-view/>
            </div>
            <Footer v-show="!is404"/>
        </div>
        <div class="mobile">
            <div>
                <MobileHeader v-show="!is404" :categories="categories" :userInfo="userInfo" style="z-index:300"/>
                <router-view/>
            </div>
            <MobileFooter v-show="!is404" style="width: 100%;" :style="$route.meta.requireAuth?'margin-bottom: 6rem;':''"/>
        </div>
    </div>
</template>
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import MobileHeader from "@/components/mobile-header";
import MobileFooter from "@/components/mobile-footer";
import {fetchCategories} from "@/api/api";
import {f} from "@/utils/util";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        MobileHeader,
        MobileFooter
    },
    data() {
        return {
            is404: false,
            categories: [],
            userInfo: {},
            fix: null,
        };
    },

    mounted() {
        window.addEventListener("scroll", this.isFixed);
        this.fetchCategories();
        this.fetchUserInfo();
    },
    methods: {
        isFixed() {
            if ((document.documentElement.scrollTop || document.body.scrollTop) >  (document.documentElement.clientHeight || document.body.clientHeight)){
                this.fix = false
            }else {
                this.fix = true
            }
        },
        clearUserInfo() {
            this.userInfo = {};
        },
        async fetchUserInfo() {
            const accessToken = sessionStorage.getItem("accessToken");
            if (accessToken) {
                this.$store
                    .dispatch("user/getInfo")
                    .then(userInfo => {
                        this.userInfo = userInfo;
                        console.log("用户信息：", f(this.userInfo));
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                console.log("没有token");
            }
        },
        async fetchCategories() {
            const response = await fetchCategories();
            if (response.code === 0) {
                this.categories = response.data.list;
            }
            console.log("分类列表：", f(this.categories));
        }
    },
    watch: {
        $route: {
            handler(e) {
                switch (e.name) {
                    case "404":
                        this.is404 = true;
                        break;
                    default:
                        this.is404 = false;
                        break;
                }
            }
        }
    }
};
</script>
<style>
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    background-color: #fff;
    --inner-height: 100vh;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    font-family: 'Montserrat', Arial, Basic Commercial LT Std, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 0.8125rem;
    height: 100%;
//width: 100%;
//height: 100%;
//overflow: hidden;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

@media (min-width: 1280px) {
    .pc {
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mobile {
        display: none;
    }
}

@media (max-width: 1280px) {
    .pc {
        display: none;
    }

    .mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        overflow: scroll;
    }
}

::v-deep .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    line-height: 30px;
    word-break: break-all;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #212121;
    font-size: 0.8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    word-break: break-all;
}

::v-deep .el-checkbox__inner {
    border-color: #212121;
    width: 0.5rem;
    height: 0.5rem;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #212121;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #212121;
    border: none;
}

::v-deep .el-checkbox__input {
    vertical-align: top;
    line-height: 2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    display: none;
}

::v-deep .el-checkbox {
    margin-bottom: 1rem;
    padding: 0 1rem;
}
</style>
