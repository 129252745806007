import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/home")
    },
    {
        path: "/shop",
        name: "shop",
        component: () => import("@/views/shopList")
    },
    {
        path: "/products/:id",
        name: "productDetail",
        meta: {
            requireAuth: true,
        },
        component: () => import("@/views/productDetail")
    },
    {
        path: "/myAccount",
        name: "myAccount",
        component: () => import("@/views/myAccount"),
        redirect: "/overView",
        children: [
            {
                path: "/overView",
                name: "overView",
                component: () => import("@/views/overView")
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import("@/views/profile")
            },
            {
                path: "/orders",
                name: "orders",
                component: () => import("@/views/orders")
            },
            {
                path: "/myAddress",
                name: "myAddress",
                component: () => import("@/views/myAddress")
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login")
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/register")
    },
    {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/views/checkout")
        // redirect: 'checkout',
        // children:[
        //   {
        //     path:"/checkout-shipping",
        //     name:"checkout-shipping",
        //     component:() => import("@/views/checkoutShipping")
        //   }
        // ]
    },
    {
        path: "/privacy",
        name: "privacyPolicy",
        component: () => import("@/views/privacyPolicy")
    },
    {
        name: "404",

        path: "/404",

        component: () => import("@/views/404.vue")
    },
    {
        path: "*",
        redirect: "/404"
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
