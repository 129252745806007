import {getCarts} from "@/api/api";

const state = {
    data: {}
};

const mutations = {
    SET_DATA: (state, info) => {
        state.data = info;
    }
};

const actions = {
    getData({commit}) {
        return new Promise((resolve, reject) => {
            getCarts().then(response => {
                const {data} = response;

                if (!data) {
                    reject("Verification failed, please Login again.");
                }

                commit("SET_DATA", data);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
