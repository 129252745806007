/**
 * 格式化 vue 数据，用于 console.log，去除...
 * @param data
 * @returns {any}
 */
export const f = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const priceTransform = (fen) => {
    return currency((fen / 100).toFixed(2) + "", "", 2);
};

const digitsRE = /(\d{3})(?=\d)/g;

/**
 * value  金额
 * currency 货币符号
 * decimals  保留位数
 */
export const currency = (value, currency, decimals) => {
    value = parseFloat(value);
    if (!isFinite(value) || (!value && value !== 0)) return "";
    currency = currency != null ? currency : "$";
    decimals = decimals != null ? decimals : 2;
    var stringified = Math.abs(value).toFixed(decimals);
    var _int = decimals
        ? stringified.slice(0, -1 - decimals)
        : stringified;
    var i = _int.length % 3;
    var head = i > 0
        ? (_int.slice(0, i) + (_int.length > 3 ? "," : ""))
        : "";
    var _float = decimals
        ? stringified.slice(-1 - decimals)
        : "";
    var sign = value < 0 ? "-" : "";
    return sign + currency + head +
        _int.slice(i).replace(digitsRE, "$1,") +
        _float;
};
